import React from 'react';
import * as classes from './Button.module.scss';

const Button = ({ children, onClick, style}) => {

  return (
    <button onClick={onClick} className={[classes.Button, classes.Centered].join(" ")}>
      {children}
    </button>
  )
}


export default Button
